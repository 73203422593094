td span {
    width: 25px;

    display: inline-block;
    text-align: center;
    margin: 0 2px;
    background: #ccc;
}

.active {
    color: red;
}
.popup {
    background: #fafafa;
}